// export const API_BASE_PATH = "https://api.seniorconnex.com"; //production
// export const SOCKET_BASE_PATH = "wss://socket.seniorconnex.com";

export const API_BASE_PATH = "https://staging-api.seniorconnex.com"; //staging
export const SOCKET_BASE_PATH = "wss://staging.socket.seniorconnex.com";
export const SOCKET_CALL_PATH = "wss://staging.socket.call.seniorconnex.com";
// export const SOCKET_CALL_PATH = "ws://localhost:4004/call";

export const API_ROUTES = {
  AUTH: {
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
  },
};
